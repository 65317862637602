import styles from "../index.css"

export const Header = () => (
  <header className="bg-transparent absolute top-0 left-0 right-0 h-20">
    <nav className="mx-auto flex items-center justify-between p-6" aria-label="Global">
      <div className="flex lg:flex-1 w-20">
        <a href="/">
          <span className="sr-only">Your Company</span>
          <img className="h-14 w-auto object-contain" src="https://assets-global.website-files.com/655f5d4f27941f65ac97445c/655f5d4f27941f65ac974490_Logo.png" alt="" />
        </a>
      </div>
    </nav>
  </header>
);