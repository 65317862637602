

export const Success = ({ companyName, trelloUrl }) => (
  <>
    <div className="text-4xl text-left mt-10">
      Let's get started!
    </div>
    <div className="text-md text-left mt-10">
      All you need to do now is to access Trello (your task management portal),
      and log in/create an account with the email you used to sign up with. The board will be called On Demand - { companyName }.
    </div>
    <button className="buy-button button flex items-center gap-3 justify-center" onClick={ () => window.location.href = trelloUrl }>
        <div className="button__circle"></div>
        <span className="z-[1]">Take me to Trello!</span>
    </button>
  </>
)