import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cube';
import 'swiper/css/pagination';

import { Autoplay, EffectCube } from 'swiper/modules';


export const OddaSwiper = () => {
  return (
    <div className="relative p-5 translate-y-[-50%] top-1/2">
      <h2 className="text-white text-xl pb-6">You're on the way to website bliss...</h2>
    <Swiper
      modules={[EffectCube, Autoplay]} effect="cube"
      autoplay={{
        delay: 3500,
        disableOnInteraction: false,
      }}
      loop={ true }
    >
      <SwiperSlide><img src="https://assets-global.website-files.com/655f5d4f27941f65ac97445c/655f5d4f27941f65ac9744c2_Illustation3-p-1080.png" /></SwiperSlide>
      <SwiperSlide><img src="https://assets-global.website-files.com/655f5d4f27941f65ac97445c/655f5d4f27941f65ac9744ae_Illustation-p-1080.png" /></SwiperSlide>
      <SwiperSlide><img src="https://assets-global.website-files.com/655f5d4f27941f65ac97445c/655f5d4f27941f65ac9744af_Illustration2-p-1080.png" /></SwiperSlide>
    </Swiper>
    </div>
  );
};