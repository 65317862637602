import { useLocationProperty } from "wouter/use-location";
import { CheckIcon } from '@heroicons/react/24/solid'



export const Steps = () => {
  const hashLocation = () => window.location.hash.replace(/^#/, "") || "/";
  const location = useLocationProperty(hashLocation);

  
  const getStatus = (step) => {
    if (location === '/success') {
      return 'complete';
    }
    else if (step === 'company-details' && location === '/company-details') {
      return 'current';
    }
    else if (step === 'company-details' && location === '/payment-information') {
      return 'complete';
    }
    else if (step === "payment-information" && location === '/company-details') {
      return 'upcoming';
    }
    else {
      return 'current';
    }
  }
  const steps = [
    { id: '1', name: 'Company details', href: null, status: getStatus('company-details') },
    { id: '2', name: 'Payment information', href: null, status: getStatus('payment-information') }
  ]
  
  return (
    <nav aria-label="Progress" className="mb-6">
      
      <ol role="list" className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
        {steps.map((step, stepIdx) => (
          <li key={step.name} className="relative md:flex md:flex-1">
            {step.status === 'complete' ? (
              <a href={step.href} className="group flex w-full items-center">
                <span className="flex items-center px-6 py-4 text-xs font-medium">
                  <span className="flex h-5 w-5 flex-shrink-0 items-center justify-center rounded-full bg-odda-blue group-hover:bg-odda-blue-800">
                    <CheckIcon className="h-3 w-3 text-white" aria-hidden="true" />
                  </span>
                  <span className="ml-4 text-left text-xs font-medium text-gray-900">{step.name}</span>
                </span>
              </a>
            ) : step.status === 'current' ? (
              <a href={step.href} className="flex items-center px-6 py-4 text-xs font-medium" aria-current="step">
                <span className="flex h-5 w-5 flex-shrink-0 items-center justify-center rounded-full border-2 border-odda-blue">
                  <span className="text-odda-blue-600">{step.id}</span>
                </span>
                <span className="ml-4 text-left text-xs font-medium text-odda-blue">{step.name}</span>
              </a>
            ) : (
              <div className="group flex items-center">
                <span className="flex items-center px-6 py-4 text-xs font-medium">
                  <span className="flex h-5 w-5 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                    <span className="text-gray-500 group-hover:text-gray-900">{step.id}</span>
                  </span>
                  <span className="ml-4 text-left text-xs font-medium text-gray-500 group-hover:text-gray-900">{step.name}</span>
                </span>
              </div>
            )}

            {stepIdx !== steps.length - 1 ? (
              <>
                {/* Arrow separator for lg screens and up */}
                <div className="absolute right-0 top-0 hidden h-full w-5 md:block" aria-hidden="true">
                  <svg
                    className="h-full w-full text-gray-300"
                    viewBox="0 0 22 80"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M0 -2L20 40L0 82"
                      vectorEffect="non-scaling-stroke"
                      stroke="currentcolor"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
  )
}
