import React, { useState } from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from "@stripe/stripe-js";
import { Route, Router } from "wouter";
import { useLocationProperty, navigate } from "wouter/use-location";

import { CheckoutForm } from "./CheckoutForm";
import { Header } from "./components/Header";
import { Steps } from "./components/Steps";
import { Success } from "./components/Success";
import { OddaSwiper } from "./components/OddaSwiper";
import { CompanyDetails } from "./components/CompanyDetails";
import './App.css';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

const options = {
  mode: 'setup',
  currency: 'aud',
  paymentMethodCreation: 'manual',
  fonts: [{ cssSrc: 'https://fonts.googleapis.com/css?family=Manrope' }],
  appearance: {
    variables: {
      fontFamily: 'Manrope',
      fontSizeBase: '17px'
    }
  }
}

function App() {
  const [customerId, setCustomerId] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [fullName, setFullName] = useState('');
  const [trelloUrl, setTrelloUrl] = useState('');

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TOKEN);
  const hashLocation = () => window.location.hash.replace(/^#/, "") || "/";
  const hashNavigate = (to) => navigate("#" + to);
  const useHashLocation = () => {
    const location = useLocationProperty(hashLocation);
    return [location, hashNavigate];
  };

  return (
    <div className="App">
      <Header />
      
      <div className="bg-odda-beige min-h-screen">
        <div className="md:w-1/2 pt-28 px-8">
          <Steps />

          <Router hook={useHashLocation}>
            <Route path="/company-details">
              <CompanyDetails
                setCustomerId={ setCustomerId }
                setCompanyName={ setCompanyName }
                setCustomerEmail={ setCustomerEmail }
                setFullName={ setFullName }
              />
            </Route>
            <Route path="/payment-information">
              <Elements stripe={stripePromise} options={options} >
                <CheckoutForm
                  customerId={ customerId }
                  customerEmail={ customerEmail }
                  companyName={ companyName }
                  fullName={ fullName }
                  setTrelloUrl={ setTrelloUrl }
                />
              </Elements>
            </Route>

            <Route path="/success">
              <Success companyName={ companyName } trelloUrl={ trelloUrl } /> 
            </Route>
            
          </Router>
          
        </div>
        
        <div className=" top-0 right-0 bottom-0 bg-odda-blue hidden md:block md:fixed md:w-1/2">
          <OddaSwiper />    
        </div>
      
      </div>
      
    </div>
  );
}

export default App;
